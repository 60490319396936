<template>
  <main>
    <img src="@/assets/logo.png" alt="" />
    <p id="dotCom">www.code-small.com</p>
    <p id="comingSoon">Coming Soon</p>
  </main>
</template>

<script>
export default {
  name: "ComingSoon",
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables";

main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & img {
    border: 1px solid rgb(59, 59, 59);
    box-shadow: 8px 8px 10px -6px #000000;
  }

  & p {
    color: white;
    font-family: $primaryFont;
    margin: 1rem 0;
    padding: 0;
    font-size: 1.25rem;

    &#dotCom {
      margin-bottom: 0;
    }

    &#comingSoon {
      font-size: 2rem;
      margin-top: 0;
      font-weight: 600;
    }
  }
}
</style>
