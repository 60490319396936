import { createRouter, createWebHistory } from "vue-router";
const TutorialList = () => import("@/components/tutorials/TutorialList");
const ComingSoon = () => import("@/components/ComingSoon");
const Home = () => import("@/components/home/Home");
const Login = () => import("@/components/auth/Login");
const TutorialForm = () => import("@/components/tutorials/TutorialForm");

let router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "is-active",
  routes: [
    { path: "/", name: "ComingSoon", component: ComingSoon },

    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { authRequired: true },
    },
    {
      path: "/home",
      name: "Home",
      component: Home,
      meta: { authRequired: true },
      children: [
        {
          path: "/tutorials",
          name: "TutorialList",
          component: TutorialList,
          meta: { authRequired: true },
        },
        {
          path: "/tutorial-form",
          name: "TutorialForm",
          component: TutorialForm,
          //   meta: { authRequired: true },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  let { authRequired } = to.meta;

  if (authRequired) {
    next("/");
  } else {
    next();
  }
});

export default router;
